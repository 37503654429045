import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import classNames from 'classnames';
import CalculationResult from './CalculationResult';
import { OptionType, getValue, PaymentFrequencyOptions } from '../utils/utils';
import { Loan } from '../engine';
import jsonData from '../assets/interest-rates.json';
import { NumericFormat } from 'react-number-format'
import { Form, InputGroup } from 'react-bootstrap';
declare global {
    interface Window { 
      _hsq: any[];
    }
  }
function MortgageCalculator() {

	const navigate = useNavigate();
	const location = useLocation();
	const { search } = location;

    const [mortgageTypeOptions, setMortgageTypeOptions] = React.useState<Array<OptionType>>([]);
	const [loanTermOptions, setLoanTermOptions] = React.useState<Array<OptionType>>([]);
    const [mortgageTypeState, setMortgageTypeState] = React.useState(new URLSearchParams(location.search).get('mortgageType') || '');

    const [loanTermState] = React.useState(new URLSearchParams(location.search).get('loanTerm') || '');
    const [interestRateState] = React.useState(new URLSearchParams(location.search).get('interestRate') || '');
    const [paymentFrequencyState] = React.useState(new URLSearchParams(location.search).get('paymentFrequency') || '');
    const [propertyAmountState] = React.useState(new URLSearchParams(location.search).get('propertyAmount') || '');
    const [downpaymentAmountState] = React.useState(new URLSearchParams(location.search).get('downpaymentAmount') || '');
    const [amortizationDurationInYearsState] = React.useState(new URLSearchParams(location.search).get('amortizationDurationInYears') || '');
    const [mortgageAmountState] = React.useState(new URLSearchParams(location.search).get('mortgageAmount') || '');
    const [showLoanTerm, setShowLoanTerm] = React.useState<boolean>(false);
    
    const searchParams = React.useMemo(() => new URLSearchParams(search), [search]);
    
    const { handleSubmit, control, reset, watch, setValue} = useForm({
        defaultValues: {
            mortgageType: mortgageTypeState,
            loanTerm: loanTermState,
            interestRate: interestRateState,
            paymentFrequency: paymentFrequencyState,
            amortizationDurationInYears: amortizationDurationInYearsState,
            propertyAmount: propertyAmountState,
            downpaymentAmount: downpaymentAmountState,
            mortgageAmount: mortgageAmountState,
        },
        mode: 'onSubmit',
        reValidateMode: 'onChange'
    });

    const watchPropertyAmount = watch("propertyAmount", '');
    const watchDownpaymentAmount = watch("downpaymentAmount", '');
    const watchLoanTerm = watch("loanTerm", '');

    React.useEffect(() => {
        const mortgageTypeResponse = Object.keys(jsonData.clientRates);
        const clientRates = jsonData.clientRates;
        setMortgageTypeOptions(mortgageTypeResponse.map((x) => {
            const name = clientRates[x as keyof typeof clientRates].name;
            return {
                value: x,
                label: name
            };
        }));
    }, [])

    React.useEffect(() => {
        if (mortgageTypeState !== '') {
            const clientRates = jsonData.clientRates;
            const terms = clientRates[mortgageTypeState as keyof typeof clientRates].items;
            setLoanTermOptions(terms.map((x) => {
                return {
                    value: x.term,
                    label: x.term,
                    data: x.rate
                };
            }));
            setShowLoanTerm(true)
        }
    }, [mortgageTypeState]);
    
    React.useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name) {
                let paramValue = value[name!];

                if (name === 'mortgageType' || name === 'paymentFrequency' || name === 'loanTerm') {
                    paramValue = (paramValue as unknown as OptionType).value;

                    if (name === 'mortgageType') {
                        setMortgageTypeState(paramValue as string);
                    }
                }

                if (name === 'mortgageAmount') {
                    paramValue = `$ ${Number(paramValue)?.toLocaleString("en", { minimumFractionDigits: 2})}`;
                }

                searchParams.set(name!, paramValue as string);
                navigate({
                    pathname: '/',
                    search: searchParams.toString()
                });
            }
		});
		return () => subscription.unsubscribe()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watch])

    React.useEffect(() => {
    const subscription = () => {
        if (watchPropertyAmount !== '' || watchDownpaymentAmount) {
            const propAmount = parseFloat(watchPropertyAmount.replace(/[$,]+/g,""));
            
            let dpAmt = 0;

            if (watchDownpaymentAmount !== '' && watchDownpaymentAmount !== null) {
                dpAmt = parseFloat(watchDownpaymentAmount?.replace(/[$,]+/g,""));
            }
            const loanAmt = propAmount - dpAmt;
            
            setValue('mortgageAmount', loanAmt.toString());
        }
    };
    subscription();
    }, [watchPropertyAmount, watchDownpaymentAmount, setValue])

    
    React.useEffect(() => {
    const subscription = () => {
        if (watchLoanTerm) {
            const data = (watchLoanTerm as unknown as OptionType).data;
            setValue('interestRate', data ?? interestRateState);
        }
    };
    subscription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchLoanTerm, setValue])

    

    const onSubmit = (data: any) => {

        const years = parseInt(data.amortizationDurationInYears);
        const rate = parseFloat(data.interestRate);
        const mortgageAmount = parseInt(data.mortgageAmount);
        const frequency = (data.paymentFrequency as any).value ?? paymentFrequencyState;

		let loan = new Loan();
		loan.amount = mortgageAmount;
		loan.years = years;
		loan.interestRate = rate;
		loan.repaymentFrequency = frequency;
		loan.rounding = 2;
		const payment = loan.payments;

        let amount = 0;

        //HUBSPOT HACK
        var _hsq = window._hsq = window._hsq || [];
        _hsq.push(["trackCustomBehavioralEvent", {
            name: "mortgage_calculator",
            properties: {
                amount: loan.amount,
                years: loan.years,
                interestRate: loan.interestRate,
                repaymentFrequency: loan.repaymentFrequency
            }
        }]);

        if (payment.length > 0) {  
            amount = payment[0].amount;
        }
		
        searchParams.set('paymentAmount', amount.toString());
		navigate({
			pathname: '/',
			search: searchParams.toString()
		});
	};

    const onReset = () => {
        setShowLoanTerm(false);
        navigate({
            pathname: '/'});
        reset();
    };

    return (
        <div className='container-fluid'>
            <div>
                <h1 className='text-center'>ATB Financial</h1>
            </div>
            <div className='row'>
                <div className="col-lg-6">
                    <div className='card'>
                        <div className='card-body'>
                            <div className='card-title'>
                                <h2 className='text-center'>Mortgage Payment Calculator</h2>
                            </div>
                            <div className="row">
                                <div className='col'>
                                    <div className='row'>
                                        <Controller
                                            control={control}
                                            name="mortgageType"
                                            aria-labelledby=""
                                            rules={
                                                {
                                                    required: 'Mortgage Type is required',
                                                }
                                            }
                                            render={({ field, fieldState: { error } }) =>
                                                <div className="mb-3">
                                                    <label id="r1" >Mortgage Type</label>
                                                    <ReactSelect
                                                    aria-labelledby='r1'
                                                        id={field.name}
                                                        name={field.name}
                                                        className={classNames(error && 'is-invalid')}
                                                        value={getValue(field.value, mortgageTypeOptions)}
                                                        defaultValue={field.value.toString()}
                                                        options={mortgageTypeOptions.map((x) => {
                                                            return {
                                                                value: x.value,
                                                                label: x.label
                                                            };
                                                        })}
                                                        onChange={field.onChange}
                                                        placeholder="Select ..."
                                                    />
                                                    {error && <p className="text-danger">{error.message}</p>}
                                                </div>
                                            }
                                        />
                                    </div>
                                    {showLoanTerm &&
                                    <>
                                        <div className='row'>
                                            <Controller
                                                control={control}
                                                name="loanTerm"
                                                aria-labelledby=""
                                                rules={
                                                    {
                                                        required: 'Loan Term is required',
                                                    }
                                                }
                                                render={({ field, fieldState: { error } }) =>
                                                    <div className="mb-3">
                                                        <label htmlFor={field.name}>Loan Term</label>
                                                        <ReactSelect
                                                            id={field.name}
                                                            name={field.name}
                                                            className={classNames(error && 'is-invalid')}
                                                            value={getValue(field.value, loanTermOptions)}
                                                            defaultValue={field.value.toString()}
                                                            options={loanTermOptions}
                                                            onChange={field.onChange}
                                                            placeholder="Select ..."
                                                        />
                                                        {error && <p className="text-danger">{error.message}</p>}
                                                    </div>
                                                }
                                            />
                                        </div>
                                    
                                        <div className='row'>
                                            <Controller
                                                control={control}
                                                name="interestRate"

                                                aria-labelledby=""
                                                render={({ field }) =>
                                                    <div className="mb-3">
                                                        <label htmlFor={field.name}>Interest Rate</label>
                                                        <InputGroup className="mb-3">
                                                            <Form.Control
                                                                aria-label="Interest Rate"
                                                                aria-describedby="interestRate"
                                                                id={field.name}
                                                                name={field.name}
                                                                value={field.value}
                                                                onChange={field.onChange}
                                                                readOnly={true}
                                                            />
                                                            <InputGroup.Text id="interestRate">%</InputGroup.Text>
                                                        </InputGroup>
                                                    </div>
                                                }
                                            />
                                        </div>
                                    </>
                                    }
									<div className='row'>
										<Controller
											control={control}
											name="paymentFrequency"
											aria-labelledby=""
											render={({ field }) =>
												<div className="mb-3">
													<label id='r2' htmlFor="">Payment Frequency</label>
													<ReactSelect
                                                    aria-labelledby='r2'
														id={field.name}
														name={field.name}
														value={getValue(field.value, PaymentFrequencyOptions)}
														options={PaymentFrequencyOptions.map((x) => {
															return {
																value: x.value,
																label: x.label
															};
														})}
														onChange={field.onChange}
														placeholder="Select ..."
													/>
												</div>
											}
										/>
									</div>

									<div className='row'>
										<Controller
											control={control}
											name="propertyAmount"
											aria-labelledby=""
                                            rules={
                                                {
                                                    required: 'Property amount is required',
                                                    validate: (value) => value !== '0' || 'Property amount must be greater than 0',
                                                }
                                            }
											render={({ field: { ref, name, value, onChange, ...rest }, fieldState: {error} }) =>
												<div className="mb-3">
													<label htmlFor={name}>Property Amount</label>
                                                    <NumericFormat
                                                        className="form-control"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        prefix="$ "
                                                        allowNegative={false}
                                                        decimalScale={2}
                                                        getInputRef={ref}
                                                        id={name}
														name={name}
														value={value}
														onChange={onChange}
                                                        fixedDecimalScale={true}
                                                        allowLeadingZeros={false}
                                                        {...rest}
                                                    />
                                                    {error && <p className="text-danger">{error.message}</p>}
												</div>
											}
										/>
									</div>
									<div className='row'>
										<Controller
											control={control}
											name="downpaymentAmount"
											aria-labelledby=""
											render={({ field: { ref, name, value, onChange, ...rest } }) =>
												<div className="mb-3">
													<label htmlFor={name}>Downpayment Amount</label>
                                                    <NumericFormat
                                                        className="form-control"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        fixedDecimalScale={true}
                                                        allowNegative={false}
                                                        prefix="$ "
                                                        decimalScale={2}
                                                        getInputRef={ref}
                                                        id={name}
														name={name}
														value={value}
														onChange={onChange}
                                                        {...rest}
                                                    />
												</div>
											}
										/>
									</div>
									<div className='row'>
										<Controller
											control={control}
											name="amortizationDurationInYears"
											aria-labelledby=""
                                            rules={
                                                {
                                                    required: 'Amortization Duration is required',
                                                    validate: (value) => value !== '0' || 'Amortization Duration must be greater than 0',
                                                }
                                            }
											render={({ field: { ref, name, value, onChange, ...rest }, fieldState: { error } }) =>
												<div className="mb-3">
													<label htmlFor={name}>Amortization Duration</label>
                                                    <InputGroup>
                                                    <NumericFormat
                                                        className="form-control"
                                                        allowNegative={false}
                                                        decimalScale={0}
                                                        getInputRef={ref}
                                                        id={name}
														name={name}
														value={value}
														onChange={onChange}
                                                        allowLeadingZeros={false}
                                                        {...rest}
                                                    />
                                                        <InputGroup.Text id="years">years</InputGroup.Text>
                                                    </InputGroup>
                                                    {error && <p className="text-danger">{error.message}</p>}
												</div>
											}
										/>
									</div>
									<div className='row'>
										<Controller
											control={control}
											name="mortgageAmount"
											aria-labelledby=""
											render={({ field: { ref, name, value, onChange, ...rest } }) =>
												<div className="mb-3">
													<label htmlFor={name}>Mortgage Amount</label>
													<NumericFormat
                                                        className="form-control"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        prefix="$ "
                                                        decimalScale={2}
                                                        getInputRef={ref}
                                                        id={name}
														name={name}
														value={value}
														onChange={onChange}
                                                        disabled
                                                        fixedDecimalScale={true}
                                                        {...rest}
                                                    />
												</div>
											}
										/>
									</div>
									<div className='row'>
										<div className='d-flex flex-row justify-content-between'>
											<button type="submit" className="btn btn-primary" onClick={handleSubmit(onSubmit)}>Calculate</button>
											<button type="button" className="btn btn-secondary" onClick={onReset}>Reset</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='col-lg-6'>
					<CalculationResult />
				</div>
			</div>
		</div>
	);
}

export default MortgageCalculator;
