import React from 'react';
import { useLocation } from 'react-router-dom';
import jsonData from '../assets/interest-rates.json';
import { PaymentFrequencyOptions } from '../utils/utils';

const CalculationResult: React.FC = () => {
    const location = useLocation();

    const [mortgageType, setMortgageType] = React.useState<string>('');
    const [loanTerm, setLoanTerm] = React.useState<string>('');
    const [interestRate, setInterestRate] = React.useState<string>('');
    const [paymentFrequency, setPaymentFrequency] = React.useState<string>('');
    const [propertyAmount, setPropertyAmount] = React.useState<string>('');
    const [downpaymentAmount, setDownpaymentAmount] = React.useState<string>('');
    const [mortgageAmount, setMortgageAmount] = React.useState<string>('');
    const [paymentAmount, setPaymentAmount] = React.useState<string>('');
    const [amortizationDurationInYears, setAmortizationDurationInYears] = React.useState<string>('');

    const mailToBody = `Please click (or copy) the link to get a copy of your Mortgage Calculation Result:%0A%0A
    ${encodeURIComponent(window.location.href)}%0A%0A%0A%0A`;

    React.useEffect(() => {
        const mortgageTypeParam = new URLSearchParams(location.search).get('mortgageType');
        const loanTermParam = new URLSearchParams(location.search).get('loanTerm');
        const interestRateParam = new URLSearchParams(location.search).get('interestRate');
        const paymentFrequencyParam = new URLSearchParams(location.search).get('paymentFrequency');
        const propertyAmountParam = new URLSearchParams(location.search).get('propertyAmount');
        const downpaymentAmountParam = new URLSearchParams(location.search).get('downpaymentAmount');
        const mortgageAmountParam = new URLSearchParams(location.search).get('mortgageAmount');
        const paymentAmountParam = new URLSearchParams(location.search).get('paymentAmount');
        const amortizationDurationInYearsParam = new URLSearchParams(location.search).get('amortizationDurationInYears');

        const clientRates = jsonData.clientRates;
        const typeName = clientRates[mortgageTypeParam as keyof typeof clientRates]?.name || ''

        const frequencyName = PaymentFrequencyOptions.find((item) => item.value === paymentFrequencyParam)?.label || '';

        setMortgageType(typeName);
        setLoanTerm(loanTermParam || '');
        setInterestRate(interestRateParam || '');
        setPaymentFrequency(frequencyName || '');
        setPropertyAmount(propertyAmountParam || '');
        setDownpaymentAmount(downpaymentAmountParam || '');
        setMortgageAmount(mortgageAmountParam || '');
        setPaymentAmount(paymentAmountParam || '');
        setAmortizationDurationInYears(amortizationDurationInYearsParam || '');
    }, [location]);

    return (
        <div>
            <div className='card'>
                <div className='card-body'>
                    <div className='card-title'>
                    <h2 className='text-center'>Mortgage Calculation Result</h2>
                    </div>
                    <div className='row'>
                        <div className='col text-center'>
                            <h1 className='text-center'>${paymentAmount}</h1>
                            {paymentFrequency !== '' 
                                && amortizationDurationInYears !== '' 
                                && <span>
                                        {paymentFrequency} payments for {amortizationDurationInYears} years
                                    </span>}                        
                        </div>
                    </div>
                    <div className='row m-4 p-4'>
                        <table className='table'>
                            <tbody>
                                <tr>
                                    <th scope="row">Mortgage Type</th>
                                    <td>{mortgageType}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Loan Term</th>
                                    <td>{loanTerm}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Interest Rate</th>
                                    <td>{interestRate} %</td>
                                </tr>
                                <tr>
                                    <th scope="row">Payment Frequency</th>
                                    <td>{paymentFrequency}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Payment Duration</th>
                                    <td>{amortizationDurationInYears} years</td>
                                </tr>
                                <tr>
                                    <th scope="row">Property Amount</th>
                                    <td>{propertyAmount}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Downpayment Amount</th>
                                    <td>{downpaymentAmount}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Mortgage Amount</th>
                                    <td>{mortgageAmount}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {paymentAmount && <div className='row'>
                        <div className='d-flex flex-row justify-content-end'>
                            <button type="button" className="btn btn-primary" onClick={() => window.location.href = `mailto:?subject=Mortgage Calculation Result&body=${mailToBody}`}>Email Report</button>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    );
};

export default CalculationResult;
