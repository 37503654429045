import { ActionMeta, GroupBase, OptionsOrGroups } from "react-select";

export function getValue(value: unknown, options: OptionsOrGroups<unknown, GroupBase<unknown>> | undefined) {
    if (Array.isArray(value)) {
        const val = options?.filter((v: any) => value.findIndex((j: any) => j.value === v.value) !== -1);
        return val;
    }
    if (typeof value === 'string') {
        const val = options?.filter((v: any) => v.value === value);
        return val;
    }
    return undefined;
}

export function onChangeVal(newValue: unknown, actionMeta: ActionMeta<unknown>, onChange: any) {
    if (onChange === undefined) {
        return;
    }
    if (newValue && Array.isArray(newValue)) {
        const value = newValue.map(v => v.value);
        onChange(value, actionMeta);
        return;
    }
    if (newValue && Object.keys(newValue).includes('value')) {
        const thewNewValue = newValue as { value: any; label: any; };
        const value = thewNewValue.value!;
        onChange(value, actionMeta);
        return;
    }
    onChange(undefined, actionMeta);
    return undefined;
}

export type OptionType = {
    value: string;
    label: string;
    data?: any;
};

export const PaymentFrequencyOptions: OptionType[] = [
	{ value: 'monthly', label: 'Monthly' },
	{ value: 'fortnightly', label: 'Bi-Weekly' },
	{ value: 'weekly', label: 'Weekly' },
];

export const getDefaultNum = (value: string | null) => {
	if (value) {
		return parseInt(value);
	}

	return 0;
};