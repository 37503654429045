import React from 'react';
import './App.css';
import MortgageCalculator from './components/MortgageCalculator';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  
  return (
    <Router>
      <Routes>
		    <Route path="/" element={<MortgageCalculator />}/>
      </Routes>
    </Router>
  );
}

export default App;
